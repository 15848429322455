// components/LeaderboardTable.js
import React, { useState, useEffect } from "react";
import { EntryCell } from "./EntryCell";
import { FaStar } from "react-icons/fa";
import { db } from "../firebase"; // Adjust this import based on your Firebase setup
import { doc, getDoc } from "firebase/firestore";

export const LeaderboardTable = ({
  leaderboardEntries,
  scores,
  handleStarClick,
  highlightedEntries,
}) => {
  const [podiumPlaces, setPodiumPlaces] = useState(6); // Default value

  useEffect(() => {
    const fetchPodiumPlaces = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, "settings", "appConfig"));
        if (settingsDoc.exists()) {
          const appConfig = settingsDoc.data();
          setPodiumPlaces(appConfig.podiumPlaces || 6);
        }
      } catch (error) {
        console.error("Error fetching podium places:", error);
      }
    };

    fetchPodiumPlaces();
  }, []);

  return (
    <table className="relative">
      <thead>
        <tr>
          <th>Pos</th>
          <th className="star-header"></th>
          <th colSpan="1">
            <EntryCell score={null} player="Entry"></EntryCell>
          </th>
          <th colSpan="2" scope="colgroup">
            <EntryCell score={null} player="Player 1"></EntryCell>
          </th>
          <th colSpan="2" scope="colgroup">
            <EntryCell score={null} player="Player 2"></EntryCell>
          </th>
          <th colSpan="2" scope="colgroup">
            <EntryCell score={null} player="Player 3"></EntryCell>
          </th>
          <th colSpan="2" scope="colgroup">
            <EntryCell score={null} player="Player 4"></EntryCell>
          </th>
          <th colSpan="2" scope="colgroup">
            <EntryCell score={null} player="Player 5"></EntryCell>
          </th>
        </tr>
      </thead>
      <tbody>
        {leaderboardEntries.map((entry, i) => (
          <React.Fragment key={entry.EntryName}>
            <tr
              className={`
                ${highlightedEntries[entry.EntryName] ? "favorite" : ""}
              `}
            >
              <td className="position">{i + 1}</td>
              <td className="star">
                <FaStar
                  className={`star-icon ${
                    highlightedEntries[entry.EntryName] ? "active" : ""
                  }`}
                  onClick={() => handleStarClick(entry.EntryName)}
                />
              </td>
              <td className="total-score">
                <EntryCell score={entry.Total} player={entry.EntryName} />
              </td>
              <td>
                <EntryCell
                  score={scores[entry.Player1]}
                  player={entry.Player1}
                />
              </td>
              <td>
                <EntryCell
                  score={scores[entry.Player2]}
                  player={entry.Player2}
                />
              </td>
              <td>
                <EntryCell
                  score={scores[entry.Player3]}
                  player={entry.Player3}
                />
              </td>
              <td>
                <EntryCell
                  score={scores[entry.Player4]}
                  player={entry.Player4}
                />
              </td>
              <td>
                <EntryCell
                  score={scores[entry.Player5]}
                  player={entry.Player5}
                />
              </td>
            </tr>
            {i === podiumPlaces - 1 && (
              <tr className="contents">
                <td className="col-span-full p-0">
                  <div className="h-[4px] bg-orange-500"></div>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

const Entries = [
  {
    EntryName: "Crowell #1",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Jon Rahm",
    Player4: "Tommy Fleetwood",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Crowell #2",
    Player1: "Scottie Scheffler",
    Player2: "Joaquín Niemann",
    Player3: "Jon Rahm",
    Player4: "Justin Thomas",
    Player5: "Bryson DeChambeau",
  },
  {
    EntryName: "Crowell #3",
    Player1: "Patrick Cantlay",
    Player2: "Xander Schauffele",
    Player3: "Jon Rahm",
    Player4: "Shane Lowry",
    Player5: "Ludvig Åberg",
  },
  {
    EntryName: "Crowell #4",
    Player1: "Ludvig Åberg",
    Player2: "Min Woo Lee",
    Player3: "Rory McIlroy",
    Player4: "Jon Rahm",
    Player5: "Akshay Bhatia",
  },
  {
    EntryName: "Conner #1",
    Player1: "Scottie Scheffler",
    Player2: "Min Woo Lee",
    Player3: "Bryson DeChambeau",
    Player4: "Jon Rahm",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "Chan #1",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Joaquín Niemann",
    Player4: "Justin Thomas",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "Chan #2",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Jon Rahm",
    Player4: "Hideki Matsuyama",
    Player5: "Jordan Spieth",
  },
  {
    EntryName: "Chan #3",
    Player1: "Xander Schauffele",
    Player2: "Ludvig Åberg",
    Player3: "Bryson DeChambeau",
    Player4: "Hideki Matsuyama",
    Player5: "Justin Thomas",
  },
  {
    EntryName: "Kayer #1",
    Player1: "Ludvig Åberg",
    Player2: "Rory McIlroy",
    Player3: "Russell Henley",
    Player4: "Sergio Garcia",
    Player5: "Akshay Bhatia",
  },
  {
    EntryName: "C. Miller #1",
    Player1: "Xander Schauffele",
    Player2: "Bryson DeChambeau",
    Player3: "Lucas Glover",
    Player4: "Rory McIlroy",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "K. Wilson #1",
    Player1: "Scottie Scheffler",
    Player2: "Phil Mickelson",
    Player3: "Jordan Spieth",
    Player4: "Jon Rahm",
    Player5: "Justin Thomas",
  },
  {
    EntryName: "K. Wilson #2",
    Player1: "Ludvig Åberg",
    Player2: "Rory McIlroy",
    Player3: "Tommy Fleetwood",
    Player4: "Viktor Hovland",
    Player5: "Tony Finau",
  },
  {
    EntryName: "K. Wilson #3",
    Player1: "Collin Morikawa",
    Player2: "Xander Schauffele",
    Player3: "Jon Rahm",
    Player4: "Jordan Spieth",
    Player5: "Cameron Smith",
  },
  {
    EntryName: "A. Justman #1",
    Player1: "Bryson DeChambeau",
    Player2: "Ludvig Åberg",
    Player3: "Justin Thomas",
    Player4: "Rory McIlroy",
    Player5: "Cameron Smith",
  },
  {
    EntryName: "M. Taylor #1",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Joaquín Niemann",
    Player4: "Viktor Hovland",
    Player5: "Min Woo Lee",
  },
  {
    EntryName: "D. Sutton #1",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Joaquín Niemann",
    Player4: "Jon Rahm",
    Player5: "Jordan Spieth",
  },
  {
    EntryName: "D. Sutton #2",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "Jon Rahm",
    Player4: "Joaquín Niemann",
    Player5: "Ludvig Åberg",
  },
  {
    EntryName: "Mittleman #1",
    Player1: "Collin Morikawa",
    Player2: "Ludvig Åberg",
    Player3: "Patrick Cantlay",
    Player4: "Bryson DeChambeau",
    Player5: "Billy Horschel",
  },
  {
    EntryName: "Stow #1",
    Player1: "Scottie Scheffler",
    Player2: "Min Woo Lee",
    Player3: "Jon Rahm",
    Player4: "Bryson DeChambeau",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Stow #2",
    Player1: "Scottie Scheffler",
    Player2: "Brooks Koepka",
    Player3: "Justin Thomas",
    Player4: "Min Woo Lee",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "Stow #3",
    Player1: "Xander Schauffele",
    Player2: "Rory McIlroy",
    Player3: "Jon Rahm",
    Player4: "Bryson DeChambeau",
    Player5: "Brooks Koepka",
  },
  {
    EntryName: "Stow #4",
    Player1: "Ludvig Åberg",
    Player2: "Collin Morikawa",
    Player3: "Bryson DeChambeau",
    Player4: "Min Woo Lee",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "Stow #5",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Sepp Straka",
    Player4: "Jordan Spieth",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "B. Williams #1",
    Player1: "Ludvig Åberg",
    Player2: "Collin Morikawa",
    Player3: "Russell Henley",
    Player4: "Robert MacIntyre",
    Player5: "Corey Conners",
  },
  {
    EntryName: "B. Williams #2",
    Player1: "Collin Morikawa",
    Player2: "Xander Schauffele",
    Player3: "Robert MacIntyre",
    Player4: "Jon Rahm",
    Player5: "Corey Conners",
  },
  {
    EntryName: "M. Fisher #1",
    Player1: "Scottie Scheffler",
    Player2: "Wyndham Clark",
    Player3: "Justin Thomas",
    Player4: "Viktor Hovland",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "M. Fisher #2",
    Player1: "Bryson DeChambeau",
    Player2: "Xander Schauffele",
    Player3: "Brooks Koepka",
    Player4: "Collin Morikawa",
    Player5: "Wyndham Clark",
  },
  {
    EntryName: "M. Fisher #3",
    Player1: "Brooks Koepka",
    Player2: "Rory McIlroy",
    Player3: "Collin Morikawa",
    Player4: "Viktor Hovland",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "J. Watts #1",
    Player1: "Ludvig Åberg",
    Player2: "Collin Morikawa",
    Player3: "Viktor Hovland",
    Player4: "Jordan Spieth",
    Player5: "Tyrrell Hatton",
  },
  {
    EntryName: "Rehland #1",
    Player1: "Scottie Scheffler",
    Player2: "Brooks Koepka",
    Player3: "Tommy Fleetwood",
    Player4: "Viktor Hovland",
    Player5: "Phil Mickelson",
  },
  {
    EntryName: "Fonner #1",
    Player1: "Rory McIlroy",
    Player2: "Jon Rahm",
    Player3: "Collin Morikawa",
    Player4: "Jordan Spieth",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Fonner #2",
    Player1: "Xander Schauffele",
    Player2: "Ludvig Åberg",
    Player3: "Jon Rahm",
    Player4: "Bryson DeChambeau",
    Player5: "Justin Thomas",
  },
  {
    EntryName: "Schaller #1",
    Player1: "Ludvig Åberg",
    Player2: "Bryson DeChambeau",
    Player3: "Justin Thomas",
    Player4: "Collin Morikawa",
    Player5: "Brooks Koepka",
  },
  {
    EntryName: "Glover #1",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Jon Rahm",
    Player4: "Justin Thomas",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "Glover #2",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Brooks Koepka",
    Player4: "Joaquín Niemann",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "Glover #3",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Jordan Spieth",
    Player4: "Viktor Hovland",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "Glover #4",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Shane Lowry",
    Player4: "Will Zalatoris",
    Player5: "Russell Henley",
  },
  {
    EntryName: "Arguson #1",
    Player1: "Viktor Hovland",
    Player2: "Ludvig Åberg",
    Player3: "Shane Lowry",
    Player4: "Xander Schauffele",
    Player5: "Russell Henley",
  },
  {
    EntryName: "D. Stein #1",
    Player1: "Scottie Scheffler",
    Player2: "Jon Rahm",
    Player3: "Russell Henley",
    Player4: "Bryson DeChambeau",
    Player5: "Justin Thomas",
  },
  {
    EntryName: "D. Stein #2",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Jon Rahm",
    Player4: "Russell Henley",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "D. Stein #3",
    Player1: "Xander Schauffele",
    Player2: "Rory McIlroy",
    Player3: "Russell Henley",
    Player4: "Brooks Koepka",
    Player5: "Justin Thomas",
  },
  {
    EntryName: "Khirallah #1",
    Player1: "Scottie Scheffler",
    Player2: "Brooks Koepka",
    Player3: "Jordan Spieth",
    Player4: "Will Zalatoris",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "B. Barclay #1",
    Player1: "Collin Morikawa",
    Player2: "Ludvig Åberg",
    Player3: "Shane Lowry",
    Player4: "Sepp Straka",
    Player5: "Russell Henley",
  },
  {
    EntryName: "Bogenreif #1",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Brooks Koepka",
    Player4: "Hideki Matsuyama",
    Player5: "Robert MacIntyre",
  },
  {
    EntryName: "Bogenreif #2",
    Player1: "Ludvig Åberg",
    Player2: "Rory McIlroy",
    Player3: "Bryson DeChambeau",
    Player4: "Jon Rahm",
    Player5: "Patrick Reed",
  },
  {
    EntryName: "Longman #1",
    Player1: "Scottie Scheffler",
    Player2: "Brooks Koepka",
    Player3: "Jordan Spieth",
    Player4: "Robert MacIntyre",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "Longman #2",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Joaquín Niemann",
    Player4: "Justin Thomas",
    Player5: "Will Zalatoris",
  },
  {
    EntryName: "D. Allen #1",
    Player1: "Scottie Scheffler",
    Player2: "Hideki Matsuyama",
    Player3: "Patrick Cantlay",
    Player4: "Tommy Fleetwood",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "Matt Miller #1",
    Player1: "Collin Morikawa",
    Player2: "Rory McIlroy",
    Player3: "Shane Lowry",
    Player4: "Bryson DeChambeau",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "Matt Miller #2",
    Player1: "Scottie Scheffler",
    Player2: "Patrick Cantlay",
    Player3: "Min Woo Lee",
    Player4: "Maverick McNealy",
    Player5: "Tom Kim",
  },
  {
    EntryName: "Matt Miller #3",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Will Zalatoris",
    Player4: "Jon Rahm",
    Player5: "Jordan Spieth",
  },
  {
    EntryName: "Matt Miller #4",
    Player1: "Xander Schauffele",
    Player2: "Rory McIlroy",
    Player3: "Hideki Matsuyama",
    Player4: "Justin Thomas",
    Player5: "Brooks Koepka",
  },
  {
    EntryName: "J. Bono #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Jon Rahm",
    Player4: "Brooks Koepka",
    Player5: "Robert MacIntyre",
  },
  {
    EntryName: "J. Derrick #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Hideki Matsuyama",
    Player4: "Viktor Hovland",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "K. Dyer #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Bryson DeChambeau",
    Player4: "Tommy Fleetwood",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "K. Dyer #2",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Max Homa",
    Player4: "Sam Burns",
    Player5: "Matt Fitzpatrick",
  },
  {
    EntryName: "K. Dyer #3",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Jordan Spieth",
    Player4: "Tommy Fleetwood",
    Player5: "Will Zalatoris",
  },
  {
    EntryName: "K. Dyer #4",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Min Woo Lee",
    Player4: "Tommy Fleetwood",
    Player5: "Akshay Bhatia",
  },
  {
    EntryName: "P. Byrd #1",
    Player1: "Justin Thomas",
    Player2: "Rory McIlroy",
    Player3: "Shane Lowry",
    Player4: "Brooks Koepka",
    Player5: "Collin Morikawa",
  },
  {
    EntryName: "P. Byrd #2",
    Player1: "Ludvig Åberg",
    Player2: "Xander Schauffele",
    Player3: "Min Woo Lee",
    Player4: "Russell Henley",
    Player5: "Akshay Bhatia",
  },
  {
    EntryName: "M. Keith #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Justin Thomas",
    Player4: "Jordan Spieth",
    Player5: "Bryson DeChambeau",
  },
  {
    EntryName: "Tully #1",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Patrick Cantlay",
    Player4: "Phil Mickelson",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "Batt #1",
    Player1: "Ludvig Åberg",
    Player2: "Collin Morikawa",
    Player3: "Patrick Reed",
    Player4: "Patrick Cantlay",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Batt #2",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Patrick Cantlay",
    Player4: "Tony Finau",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "L. Anderson #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Justin Thomas",
    Player4: "Bryson DeChambeau",
    Player5: "Will Zalatoris",
  },
  {
    EntryName: "R. Rehland #2",
    Player1: "Bryson DeChambeau",
    Player2: "Scottie Scheffler",
    Player3: "Viktor Hovland",
    Player4: "Tommy Fleetwood",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "Mark Murray #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Min Woo Lee",
    Player4: "Sepp Straka",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "Mark Murray #2",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Brooks Koepka",
    Player4: "Jon Rahm",
    Player5: "Justin Thomas",
  },
  {
    EntryName: "Mark Murray #3",
    Player1: "Rory McIlroy",
    Player2: "Jon Rahm",
    Player3: "Shane Lowry",
    Player4: "Cameron Smith",
    Player5: "Jordan Spieth",
  },
  {
    EntryName: "C. Welsh #1",
    Player1: "Rory McIlroy",
    Player2: "Akshay Bhatia",
    Player3: "Cam Davis",
    Player4: "Tommy Fleetwood",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "C. Welsh #2",
    Player1: "Scottie Scheffler",
    Player2: "Viktor Hovland",
    Player3: "Brooks Koepka",
    Player4: "Cam Davis",
    Player5: "Cameron Smith",
  },
  {
    EntryName: "C. Welsh #3",
    Player1: "Sungjae Im",
    Player2: "Min Woo Lee",
    Player3: "Brooks Koepka",
    Player4: "Scottie Scheffler",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "M. Fox #1",
    Player1: "Scottie Scheffler",
    Player2: "Jon Rahm",
    Player3: "Bryson DeChambeau",
    Player4: "Brooks Koepka",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Luzarraga #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Jon Rahm",
    Player4: "Justin Thomas",
    Player5: "Robert MacIntyre",
  },
  {
    EntryName: "Luzarraga #2",
    Player1: "Scottie Scheffler",
    Player2: "Jon Rahm",
    Player3: "Bryson DeChambeau",
    Player4: "Justin Thomas",
    Player5: "Russell Henley",
  },
  {
    EntryName: "Whitestone #1",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Patrick Cantlay",
    Player4: "Jason Day",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Hudson #",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Patrick Cantlay",
    Player4: "Bryson DeChambeau",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "Hudson #2",
    Player1: "Scottie Scheffler",
    Player2: "Jon Rahm",
    Player3: "Sepp Straka",
    Player4: "Russell Henley",
    Player5: "Robert MacIntyre",
  },
  {
    EntryName: "Wallace #1",
    Player1: "Scottie Scheffler",
    Player2: "Hideki Matsuyama",
    Player3: "Justin Thomas",
    Player4: "Tony Finau",
    Player5: "Wyndham Clark",
  },
  {
    EntryName: "Wallace #2",
    Player1: "Hideki Matsuyama",
    Player2: "Rory McIlroy",
    Player3: "Xander Schauffele",
    Player4: "Shane Lowry",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "Wallace #3",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Hideki Matsuyama",
    Player4: "Max Homa",
    Player5: "Tom Kim",
  },
  {
    EntryName: "J. Luzarraga #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Bryson DeChambeau",
    Player4: "Robert MacIntyre",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "Rakow #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Bryson DeChambeau",
    Player4: "Shane Lowry",
    Player5: "Joaquín Niemann",
  },
  {
    EntryName: "Balkin #1",
    Player1: "Scottie Scheffler",
    Player2: "Will Zalatoris",
    Player3: "Cameron Smith",
    Player4: "Patrick Cantlay",
    Player5: "Corey Conners",
  },
  {
    EntryName: "Balkin #2",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Sepp Straka",
    Player4: "Tyrrell Hatton",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "L. Odom #1",
    Player1: "Shane Lowry",
    Player2: "Rory McIlroy",
    Player3: "Collin Morikawa",
    Player4: "Patrick Cantlay",
    Player5: "Russell Henley",
  },
  {
    EntryName: "Honrbeak #1",
    Player1: "Collin Morikawa",
    Player2: "Cameron Smith",
    Player3: "Xander Schauffele",
    Player4: "Keegan Bradley",
    Player5: "Min Woo Lee",
  },
  {
    EntryName: "R. Jacobs #1",
    Player1: "Xander Schauffele",
    Player2: "Viktor Hovland",
    Player3: "Patrick Cantlay",
    Player4: "Tommy Fleetwood",
    Player5: "Brian Harman",
  },
  {
    EntryName: "R. Jacobs #2",
    Player1: "Collin Morikawa",
    Player2: "Xander Schauffele",
    Player3: "Shane Lowry",
    Player4: "Patrick Reed",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "Ritchie #1",
    Player1: "Collin Morikawa",
    Player2: "Xander Schauffele",
    Player3: "Russell Henley",
    Player4: "Tommy Fleetwood",
    Player5: "Joaquín Niemann",
  },
  {
    EntryName: "Stensgard #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Hideki Matsuyama",
    Player4: "Viktor Hovland",
    Player5: "Will Zalatoris",
  },
  {
    EntryName: "Stensgard #2",
    Player1: "Scottie Scheffler",
    Player2: "Corey Conners",
    Player3: "Will Zalatoris",
    Player4: "Akshay Bhatia",
    Player5: "Tyrrell Hatton",
  },
  {
    EntryName: "Sharp  #1",
    Player1: "Collin Morikawa",
    Player2: "Rory McIlroy",
    Player3: "Tom Kim",
    Player4: "Adam Scott",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "Sharp #2",
    Player1: "Scottie Scheffler",
    Player2: "Joaquín Niemann",
    Player3: "Tommy Fleetwood",
    Player4: "Lucas Glover",
    Player5: "Will Zalatoris",
  },
  {
    EntryName: "Pinkston #1",
    Player1: "JJ Spaun",
    Player2: "Shane Lowry",
    Player3: "Corey Conners",
    Player4: "Akshay Bhatia",
    Player5: "Justin Thomas",
  },
  {
    EntryName: "Prehn #1",
    Player1: "Bryson DeChambeau",
    Player2: "Scottie Scheffler",
    Player3: "Jon Rahm",
    Player4: "Justin Thomas",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Belleson #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Bryson DeChambeau",
    Player4: "Shane Lowry",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "Skizas #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Jon Rahm",
    Player4: "Bryson DeChambeau",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "Hoard #1",
    Player1: "Scottie Scheffler",
    Player2: "Justin Thomas",
    Player3: "Brooks Koepka",
    Player4: "Russell Henley",
    Player5: "Jordan Spieth",
  },
  {
    EntryName: "Pope #1",
    Player1: "Scottie Scheffler",
    Player2: "Wyndham Clark",
    Player3: "Brooks Koepka",
    Player4: "Russell Henley",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "Pope #2",
    Player1: "Rory McIlroy",
    Player2: "Austin Eckroat",
    Player3: "Bryson DeChambeau",
    Player4: "Shane Lowry",
    Player5: "Jason Day",
  },
  {
    EntryName: "Cifuentes #1",
    Player1: "Scottie Scheffler",
    Player2: "Viktor Hovland",
    Player3: "Bryson DeChambeau",
    Player4: "Akshay Bhatia",
    Player5: "Justin Thomas",
  },
  {
    EntryName: "T. Shaw #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Justin Thomas",
    Player4: "Bryson DeChambeau",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "T. Shaw #2",
    Player1: "Scottie Scheffler",
    Player2: "Justin Thomas",
    Player3: "Bryson DeChambeau",
    Player4: "Brooks Koepka",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "McKenzie #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Jon Rahm",
    Player4: "Bryson DeChambeau",
    Player5: "Brooks Koepka",
  },
  {
    EntryName: "Tedeschi #1",
    Player1: "Viktor Hovland",
    Player2: "Collin Morikawa",
    Player3: "Xander Schauffele",
    Player4: "Cameron Smith",
    Player5: "Will Zalatoris",
  },
  {
    EntryName: "Tedeschi #2",
    Player1: "Collin Morikawa",
    Player2: "Ludvig Åberg",
    Player3: "Shane Lowry",
    Player4: "Brooks Koepka",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "Tedeschi #3",
    Player1: "Ludvig Åberg",
    Player2: "Rory McIlroy",
    Player3: "Sepp Straka",
    Player4: "Tom Kim",
    Player5: "Bryson DeChambeau",
  },
  {
    EntryName: "Tedeschi #4",
    Player1: "Scottie Scheffler",
    Player2: "JJ Spaun",
    Player3: "Keegan Bradley",
    Player4: "Patrick Cantlay",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "Tedeschi #5",
    Player1: "Ludvig Åberg",
    Player2: "Collin Morikawa",
    Player3: "Viktor Hovland",
    Player4: "Sepp Straka",
    Player5: "Bryson DeChambeau",
  },
  {
    EntryName: "Tedeschi #6",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Will Zalatoris",
    Player4: "Patrick Cantlay",
    Player5: "Corey Conners",
  },
  {
    EntryName: "Tedeschi #7",
    Player1: "Wyndham Clark",
    Player2: "Michael Kim",
    Player3: "Brooks Koepka",
    Player4: "Shane Lowry",
    Player5: "Brian Harman",
  },
  {
    EntryName: "Tedeschi #8",
    Player1: "Jon Rahm",
    Player2: "Scottie Scheffler",
    Player3: "Bryson DeChambeau",
    Player4: "Min Woo Lee",
    Player5: "Justin Thomas",
  },
  {
    EntryName: "Tedeschi #9",
    Player1: "Jon Rahm",
    Player2: "Rory McIlroy",
    Player3: "Collin Morikawa",
    Player4: "Brian Harman",
    Player5: "Joaquín Niemann",
  },
  {
    EntryName: "Tedeschi #10",
    Player1: "Collin Morikawa",
    Player2: "Xander Schauffele",
    Player3: "Russell Henley",
    Player4: "Patrick Reed",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "S. Berry #1",
    Player1: "Scottie Scheffler",
    Player2: "Jon Rahm",
    Player3: "Brooks Koepka",
    Player4: "Justin Thomas",
    Player5: "Min Woo Lee",
  },
  {
    EntryName: "S. Berry #2",
    Player1: "Rory McIlroy",
    Player2: "Robert MacIntyre",
    Player3: "Collin Morikawa",
    Player4: "Will Zalatoris",
    Player5: "Cameron Smith",
  },
  {
    EntryName: "Searcy #1",
    Player1: "Scottie Scheffler",
    Player2: "Hideki Matsuyama",
    Player3: "Justin Thomas",
    Player4: "Shane Lowry",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "Skorpil #1",
    Player1: "Collin Morikawa",
    Player2: "Jon Rahm",
    Player3: "Xander Schauffele",
    Player4: "Tommy Fleetwood",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "Mitcham #1",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Jon Rahm",
    Player4: "Justin Thomas",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "Mitcham #2",
    Player1: "Collin Morikawa",
    Player2: "Ludvig Åberg",
    Player3: "Bryson DeChambeau",
    Player4: "Viktor Hovland",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "Mitcham #3",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Bryson DeChambeau",
    Player4: "Jon Rahm",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "Glieber #1",
    Player1: "Scottie Scheffler",
    Player2: "Jon Rahm",
    Player3: "Viktor Hovland",
    Player4: "Joaquín Niemann",
    Player5: "Keegan Bradley",
  },
  {
    EntryName: "Glieber #2",
    Player1: "Scottie Scheffler",
    Player2: "Justin Thomas",
    Player3: "Brooks Koepka",
    Player4: "Cameron Smith",
    Player5: "Sahith Theegala",
  },
  {
    EntryName: "Kasperski #1",
    Player1: "Will Zalatoris",
    Player2: "Brooks Koepka",
    Player3: "Cameron Young",
    Player4: "Bryson DeChambeau",
    Player5: "Ludvig Åberg",
  },
  {
    EntryName: "Kasperski #2",
    Player1: "Viktor Hovland",
    Player2: "Rory McIlroy",
    Player3: "Collin Morikawa",
    Player4: "Max Homa",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "P. Brown #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Bryson DeChambeau",
    Player4: "Russell Henley",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "E. Brown #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Bryson DeChambeau",
    Player4: "Hideki Matsuyama",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "Kinsaul #1",
    Player1: "Rory McIlroy",
    Player2: "Tommy Fleetwood",
    Player3: "Justin Thomas",
    Player4: "Keegan Bradley",
    Player5: "Min Woo Lee",
  },
  {
    EntryName: "Kinsaul #2",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Patrick Cantlay",
    Player4: "Russell Henley",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "Kopy #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Brooks Koepka",
    Player4: "Akshay Bhatia",
    Player5: "Min Woo Lee",
  },
  {
    EntryName: "Pfeifle #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Will Zalatoris",
    Player4: "Corey Conners",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "Pfeifle #2",
    Player1: "Scottie Scheffler",
    Player2: "Justin Thomas",
    Player3: "Russell Henley",
    Player4: "Akshay Bhatia",
    Player5: "Jordan Spieth",
  },
  {
    EntryName: "Fricke #1",
    Player1: "Rory McIlroy",
    Player2: "Wyndham Clark",
    Player3: "Collin Morikawa",
    Player4: "Justin Thomas",
    Player5: "Adam Scott",
  },
  {
    EntryName: "Fricke #2",
    Player1: "Xander Schauffele",
    Player2: "Ludvig Åberg",
    Player3: "Russell Henley",
    Player4: "Justin Thomas",
    Player5: "Wyndham Clark",
  },
  {
    EntryName: "J. Matthews #1",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Jon Rahm",
    Player4: "Justin Thomas",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "J. Matthews #2",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Bryson DeChambeau",
    Player4: "Jordan Spieth",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "P. Matthews #1",
    Player1: "Collin Morikawa",
    Player2: "Bryson DeChambeau",
    Player3: "Ludvig Åberg",
    Player4: "Hideki Matsuyama",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "Paletta #1",
    Player1: "Scottie Scheffler",
    Player2: "Shane Lowry",
    Player3: "Jon Rahm",
    Player4: "Robert MacIntyre",
    Player5: "Joaquín Niemann",
  },
  {
    EntryName: "Paletta #2",
    Player1: "Xander Schauffele",
    Player2: "Rory McIlroy",
    Player3: "Patrick Cantlay",
    Player4: "Corey Conners",
    Player5: "Cameron Smith",
  },
  {
    EntryName: "Paletta #3",
    Player1: "Collin Morikawa",
    Player2: "Hideki Matsuyama",
    Player3: "Bryson DeChambeau",
    Player4: "Russell Henley",
    Player5: "Michael Kim",
  },
  {
    EntryName: "Hullett #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Brooks Koepka",
    Player4: "Jon Rahm",
    Player5: "Russell Henley",
  },
  {
    EntryName: "Goodnight #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Hideki Matsuyama",
    Player4: "Shane Lowry",
    Player5: "Bryson DeChambeau",
  },
  {
    EntryName: "Deaver #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Justin Thomas",
    Player4: "Shane Lowry",
    Player5: "Patrick Reed",
  },
  {
    EntryName: "Nicholson #1",
    Player1: "Rory McIlroy",
    Player2: "Jason Day",
    Player3: "Bryson DeChambeau",
    Player4: "Collin Morikawa",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "Nicholson #2",
    Player1: "Scottie Scheffler",
    Player2: "Jon Rahm",
    Player3: "Joaquín Niemann",
    Player4: "Patrick Cantlay",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "Nicholson #3",
    Player1: "Scottie Scheffler",
    Player2: "Robert MacIntyre",
    Player3: "Tom Hoge",
    Player4: "Daniel Berger",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "Nicholson #4",
    Player1: "Jon Rahm",
    Player2: "Scottie Scheffler",
    Player3: "Brian Harman",
    Player4: "Bryson DeChambeau",
    Player5: "Phil Mickelson",
  },
  {
    EntryName: "Nicholson #5",
    Player1: "Scottie Scheffler",
    Player2: "Justin Rose",
    Player3: "Brooks Koepka",
    Player4: "Min Woo Lee",
    Player5: "Matthieu Pavon",
  },
  {
    EntryName: "Nicholson #6",
    Player1: "Jon Rahm",
    Player2: "Rory McIlroy",
    Player3: "Collin Morikawa",
    Player4: "Viktor Hovland",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "Nicholson #7",
    Player1: "Collin Morikawa",
    Player2: "Xander Schauffele",
    Player3: "Sungjae Im",
    Player4: "JJ Spaun",
    Player5: "Russell Henley",
  },
  {
    EntryName: "Nicholson #8",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Shane Lowry",
    Player4: "Robert MacIntyre",
    Player5: "Jordan Spieth",
  },
  {
    EntryName: "Nicholson #9",
    Player1: "Will Zalatoris",
    Player2: "Akshay Bhatia",
    Player3: "Scottie Scheffler",
    Player4: "Dustin Johnson",
    Player5: "Tony Finau",
  },
  {
    EntryName: "Nicholson #10",
    Player1: "Xander Schauffele",
    Player2: "Bryson DeChambeau",
    Player3: "Ludvig Åberg",
    Player4: "Corey Conners",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "Nicholson #11",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Hideki Matsuyama",
    Player4: "Tom Kim",
    Player5: "Akshay Bhatia",
  },
  {
    EntryName: "Nicholson #12",
    Player1: "Collin Morikawa",
    Player2: "Rory McIlroy",
    Player3: "Wyndham Clark",
    Player4: "Russell Henley",
    Player5: "Justin Thomas",
  },
  {
    EntryName: "Dormont #1",
    Player1: "Scottie Scheffler",
    Player2: "Justin Thomas",
    Player3: "Russell Henley",
    Player4: "Tommy Fleetwood",
    Player5: "Will Zalatoris",
  },
  {
    EntryName: "Dormont #2",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Patrick Cantlay",
    Player4: "Shane Lowry",
    Player5: "Corey Conners",
  },
  {
    EntryName: "B. Odom #",
    Player1: "Collin Morikawa",
    Player2: "Rory McIlroy",
    Player3: "Jon Rahm",
    Player4: "Justin Thomas",
    Player5: "Sergio Garcia",
  },
  {
    EntryName: "M. Wheeler #1",
    Player1: "Ludvig Åberg",
    Player2: "Rory McIlroy",
    Player3: "Phil Mickelson",
    Player4: "Jordan Spieth",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "M. Wheeler #2",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Sergio Garcia",
    Player4: "Jordan Spieth",
    Player5: "Will Zalatoris",
  },
  {
    EntryName: "Charles #1",
    Player1: "Xander Schauffele",
    Player2: "Bryson DeChambeau",
    Player3: "Jon Rahm",
    Player4: "Brooks Koepka",
    Player5: "Brian Campbell",
  },
  {
    EntryName: "K. O'Connell #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Tommy Fleetwood",
    Player4: "Jon Rahm",
    Player5: "Russell Henley",
  },
  {
    EntryName: "S. O'Connell #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Jordan Spieth",
    Player4: "Justin Thomas",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "D. O'Connell #1",
    Player1: "Rory McIlroy",
    Player2: "Jordan Spieth",
    Player3: "Tom Hoge",
    Player4: "Russell Henley",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "C. O'Connell #1",
    Player1: "Tommy Fleetwood",
    Player2: "Bryson DeChambeau",
    Player3: "Min Woo Lee",
    Player4: "Akshay Bhatia",
    Player5: "Lucas Glover",
  },
  {
    EntryName: "Fazio #1",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Tom Kim",
    Player4: "Jordan Spieth",
    Player5: "Will Zalatoris",
  },
  {
    EntryName: "Fazio #2",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Robert MacIntyre",
    Player4: "Tommy Fleetwood",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Fazio #3",
    Player1: "Hideki Matsuyama",
    Player2: "Collin Morikawa",
    Player3: "Min Woo Lee",
    Player4: "Sungjae Im",
    Player5: "Byeong Hun An",
  },
  {
    EntryName: "Fazio #4",
    Player1: "Jon Rahm",
    Player2: "Bryson DeChambeau",
    Player3: "Joaquín Niemann",
    Player4: "Cameron Smith",
    Player5: "Tyrrell Hatton",
  },
  {
    EntryName: "Fazio #5",
    Player1: "Scottie Scheffler",
    Player2: "Russell Henley",
    Player3: "Nick Taylor",
    Player4: "Sepp Straka",
    Player5: "Billy Horschel",
  },
  {
    EntryName: "B. Cooper #1",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Jon Rahm",
    Player4: "Wyndham Clark",
    Player5: "Will Zalatoris",
  },
  {
    EntryName: "B. Cooper #2",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Viktor Hovland",
    Player4: "Justin Thomas",
    Player5: "Jordan Spieth",
  },
  {
    EntryName: "B. Cooper #3",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Bryson DeChambeau",
    Player4: "Jon Rahm",
    Player5: "Wyndham Clark",
  },
  {
    EntryName: "B. Cooper #4",
    Player1: "Scottie Scheffler",
    Player2: "Justin Thomas",
    Player3: "Viktor Hovland",
    Player4: "Aaron Rai",
    Player5: "Akshay Bhatia",
  },
  {
    EntryName: "Annett #1",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Hideki Matsuyama",
    Player4: "Tommy Fleetwood",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "Pearce #1",
    Player1: "Scottie Scheffler",
    Player2: "Jordan Spieth",
    Player3: "Will Zalatoris",
    Player4: "Bryson DeChambeau",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "K,. Barclay #1",
    Player1: "Collin Morikawa",
    Player2: "Ludvig Åberg",
    Player3: "Bryson DeChambeau",
    Player4: "Kevin Yu",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "T. Anderson #1",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Patrick Cantlay",
    Player4: "Bryson DeChambeau",
    Player5: "Brian Harman",
  },
  {
    EntryName: "Fires #1",
    Player1: "Collin Morikawa",
    Player2: "Ludvig Åberg",
    Player3: "Jon Rahm",
    Player4: "Hideki Matsuyama",
    Player5: "Bryson DeChambeau",
  },
  {
    EntryName: "Fires #2",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Justin Thomas",
    Player4: "Viktor Hovland",
    Player5: "Maverick McNealy",
  },
  {
    EntryName: "Frederiksen #1",
    Player1: "Scottie Scheffler",
    Player2: "Jon Rahm",
    Player3: "Tommy Fleetwood",
    Player4: "Sepp Straka",
    Player5: "Russell Henley",
  },
  {
    EntryName: "Frederiksen #2",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Justin Thomas",
    Player4: "Shane Lowry",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "Frederiksen #3",
    Player1: "Collin Morikawa",
    Player2: "Ludvig Åberg",
    Player3: "Will Zalatoris",
    Player4: "Cameron Smith",
    Player5: "Bryson DeChambeau",
  },
  {
    EntryName: "L. Odom #2",
    Player1: "Rory McIlroy",
    Player2: "Hideki Matsuyama",
    Player3: "Ludvig Åberg",
    Player4: "Bryson DeChambeau",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "Meacham #1",
    Player1: "Scottie Scheffler",
    Player2: "Russell Henley",
    Player3: "Jordan Spieth",
    Player4: "Lucas Glover",
    Player5: "Keegan Bradley",
  },
  {
    EntryName: "Ross #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Bryson DeChambeau",
    Player4: "Cameron Smith",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "Jerry Wheeler #1",
    Player1: "Bryson DeChambeau",
    Player2: "Ludvig Åberg",
    Player3: "Hideki Matsuyama",
    Player4: "Wyndham Clark",
    Player5: "Joaquín Niemann",
  },
  {
    EntryName: "Jerry  Wheeler #2",
    Player1: "Ludvig Åberg",
    Player2: "Tommy Fleetwood",
    Player3: "Corey Conners",
    Player4: "Bryson DeChambeau",
    Player5: "Rory McIlroy",
  },
  {
    EntryName: "Jerry  Wheeler #3",
    Player1: "Collin Morikawa",
    Player2: "Wyndham Clark",
    Player3: "Rory McIlroy",
    Player4: "Shane Lowry",
    Player5: "Joaquín Niemann",
  },
  {
    EntryName: "C. Senter #1",
    Player1: "Ludvig Åberg",
    Player2: "Xander Schauffele",
    Player3: "Viktor Hovland",
    Player4: "Tommy Fleetwood",
    Player5: "Akshay Bhatia",
  },
  {
    EntryName: "C. Senter #2",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Bryson DeChambeau",
    Player4: "Shane Lowry",
    Player5: "Jason Day",
  },
  {
    EntryName: "C. Senter #4",
    Player1: "Brooks Koepka",
    Player2: "Xander Schauffele",
    Player3: "Viktor Hovland",
    Player4: "Ludvig Åberg",
    Player5: "Min Woo Lee",
  },
  {
    EntryName: "O. Henderson #1",
    Player1: "Rory McIlroy",
    Player2: "Wyndham Clark",
    Player3: "Ludvig Åberg",
    Player4: "Shane Lowry",
    Player5: "Tom Kim",
  },
  {
    EntryName: "O. Henderson #2",
    Player1: "Tommy Fleetwood",
    Player2: "Max Homa",
    Player3: "Ludvig Åberg",
    Player4: "Brooks Koepka",
    Player5: "Justin Thomas",
  },
  {
    EntryName: "Steele #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Hideki Matsuyama",
    Player4: "Shane Lowry",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "Shellenberger #1",
    Player1: "Scottie Scheffler",
    Player2: "Shane Lowry",
    Player3: "Russell Henley",
    Player4: "Min Woo Lee",
    Player5: "JJ Spaun",
  },
  {
    EntryName: "A. Stein #1",
    Player1: "Scottie Scheffler",
    Player2: "Will Zalatoris",
    Player3: "Bryson DeChambeau",
    Player4: "Jordan Spieth",
    Player5: "Tom Kim",
  },
  {
    EntryName: "A. Stein #2",
    Player1: "Scottie Scheffler",
    Player2: "Min Woo Lee",
    Player3: "Sungjae Im",
    Player4: "Cameron Smith",
    Player5: "Russell Henley",
  },
  {
    EntryName: "A. Stein #3",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Brooks Koepka",
    Player4: "Will Zalatoris",
    Player5: "Corey Conners",
  },
  {
    EntryName: "A. Stein #4",
    Player1: "Collin Morikawa",
    Player2: "Rory McIlroy",
    Player3: "Dustin Johnson",
    Player4: "Min Woo Lee",
    Player5: "Cameron Young",
  },
  {
    EntryName: "Kerner #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Jon Rahm",
    Player4: "Justin Thomas",
    Player5: "Joaquín Niemann",
  },
  {
    EntryName: "M. Kassing #1",
    Player1: "Rory McIlroy",
    Player2: "Jon Rahm",
    Player3: "Xander Schauffele",
    Player4: "Cameron Smith",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "M. Kassing #2",
    Player1: "Collin Morikawa",
    Player2: "Ludvig Åberg",
    Player3: "Jon Rahm",
    Player4: "Sepp Straka",
    Player5: "Cameron Smith",
  },
  {
    EntryName: "A. Reardon #1",
    Player1: "Rory McIlroy",
    Player2: "Will Zalatoris",
    Player3: "Xander Schauffele",
    Player4: "Bryson DeChambeau",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "M. Justman #1",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Sergio Garcia",
    Player4: "Joaquín Niemann",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "Gould #1",
    Player1: "Scottie Scheffler",
    Player2: "Jon Rahm",
    Player3: "Bryson DeChambeau",
    Player4: "Shane Lowry",
    Player5: "Hideki Matsuyama",
  },
  {
    EntryName: "G. Wheeler #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Jon Rahm",
    Player4: "Shane Lowry",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "G. Wheeler #2",
    Player1: "Scottie Scheffler",
    Player2: "Russell Henley",
    Player3: "Hideki Matsuyama",
    Player4: "Justin Thomas",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "G. Wheeler #3",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Justin Thomas",
    Player4: "Jon Rahm",
    Player5: "Tony Finau",
  },
  {
    EntryName: "D. Ondocsim #1",
    Player1: "Scottie Scheffler",
    Player2: "Jon Rahm",
    Player3: "Bryson DeChambeau",
    Player4: "Justin Thomas",
    Player5: "Russell Henley",
  },
  {
    EntryName: "D. Ondocsin #2",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Jon Rahm",
    Player4: "Brooks Koepka",
    Player5: "Patrick Reed",
  },
  {
    EntryName: "M. Ondocsin #1",
    Player1: "Collin Morikawa",
    Player2: "Xander Schauffele",
    Player3: "Bryson DeChambeau",
    Player4: "Joaquín Niemann",
    Player5: "Jordan Spieth",
  },
  {
    EntryName: "A. Ondocsin #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Tommy Fleetwood",
    Player4: "Viktor Hovland",
    Player5: "Maverick McNealy",
  },
  {
    EntryName: "John Wheeler #1",
    Player1: "Rory McIlroy",
    Player2: "Shane Lowry",
    Player3: "Collin Morikawa",
    Player4: "Wyndham Clark",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "W. Tyler #1",
    Player1: "Collin Morikawa",
    Player2: "Tom Hoge",
    Player3: "Akshay Bhatia",
    Player4: "Rory McIlroy",
    Player5: "Bryson DeChambeau",
  },
  {
    EntryName: "W. Tyler #2",
    Player1: "Scottie Scheffler",
    Player2: "Akshay Bhatia",
    Player3: "Bryson DeChambeau",
    Player4: "Corey Conners",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "W. Tyler #3",
    Player1: "Tom Hoge",
    Player2: "Collin Morikawa",
    Player3: "Bryson DeChambeau",
    Player4: "Brooks Koepka",
    Player5: "Corey Conners",
  },
  {
    EntryName: "W. Tyler #4",
    Player1: "Rory McIlroy",
    Player2: "Patrick Cantlay",
    Player3: "Akshay Bhatia",
    Player4: "Shane Lowry",
    Player5: "Sergio Garcia",
  },
  {
    EntryName: "W. Tyler #5",
    Player1: "Scottie Scheffler",
    Player2: "Akshay Bhatia",
    Player3: "Sergio Garcia",
    Player4: "Robert MacIntyre",
    Player5: "Bryson DeChambeau",
  },
  {
    EntryName: "W. Tyler #6",
    Player1: "Rory McIlroy",
    Player2: "Shane Lowry",
    Player3: "Sergio Garcia",
    Player4: "Hideki Matsuyama",
    Player5: "Bryson DeChambeau",
  },
  {
    EntryName: "W. Tyler #7",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "Shane Lowry",
    Player4: "Cameron Young",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "W. Tyler #8",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Bryson DeChambeau",
    Player4: "Justin Thomas",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "W. Tyler #9",
    Player1: "Collin Morikawa",
    Player2: "Shane Lowry",
    Player3: "Viktor Hovland",
    Player4: "Hideki Matsuyama",
    Player5: "Cameron Smith",
  },
  {
    EntryName: "W. Tyler #10",
    Player1: "Bryson DeChambeau",
    Player2: "Rory McIlroy",
    Player3: "Min Woo Lee",
    Player4: "Jon Rahm",
    Player5: "Cameron Smith",
  },
  {
    EntryName: "C. Sampson #1",
    Player1: "Bryson DeChambeau",
    Player2: "Ludvig Åberg",
    Player3: "Xander Schauffele",
    Player4: "Patrick Cantlay",
    Player5: "Corey Conners",
  },
  {
    EntryName: "T. Oneal #1",
    Player1: "Scottie Scheffler",
    Player2: "Will Zalatoris",
    Player3: "Bryson DeChambeau",
    Player4: "Jon Rahm",
    Player5: "Brooks Koepka",
  },
  {
    EntryName: "Matt Mitcham #1",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Will Zalatoris",
    Player4: "Jon Rahm",
    Player5: "Bryson DeChambeau",
  },
  {
    EntryName: "Matt Mitcham #2",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Will Zalatoris",
    Player4: "Justin Thomas",
    Player5: "Brooks Koepka",
  },
  {
    EntryName: "Matt Mitcham #3",
    Player1: "Rory McIlroy",
    Player2: "Will Zalatoris",
    Player3: "Xander Schauffele",
    Player4: "Brooks Koepka",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "J. Taylor #1",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "Jon Rahm",
    Player4: "Russell Henley",
    Player5: "Will Zalatoris",
  },
  {
    EntryName: "K. Dyer #5",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Hideki Matsuyama",
    Player4: "JJ Spaun",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "K. Dyer #6",
    Player1: "Collin Morikawa",
    Player2: "Ludvig Åberg",
    Player3: "Hideki Matsuyama",
    Player4: "Sergio Garcia",
    Player5: "Max Homa",
  },
  {
    EntryName: "Jurbala #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Bryson DeChambeau",
    Player4: "Viktor Hovland",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "Jurbala #2",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Bryson DeChambeau",
    Player4: "Justin Rose",
    Player5: "Joaquín Niemann",
  },
  {
    EntryName: "Jurbala #3",
    Player1: "Scottie Scheffler",
    Player2: "Justin Thomas",
    Player3: "Will Zalatoris",
    Player4: "Jon Rahm",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "M. Anderson #1",
    Player1: "Rory McIlroy",
    Player2: "Bryson DeChambeau",
    Player3: "Jon Rahm",
    Player4: "Brooks Koepka",
    Player5: "Collin Morikawa",
  },
  {
    EntryName: "C. O'Brien #1",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Tommy Fleetwood",
    Player4: "Patrick Cantlay",
    Player5: "Joaquín Niemann",
  },
  {
    EntryName: "Lavine #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Jon Rahm",
    Player4: "Tommy Fleetwood",
    Player5: "Corey Conners",
  },
  {
    EntryName: "Lavine #2",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Bryson DeChambeau",
    Player4: "Tommy Fleetwood",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "Lavine #3",
    Player1: "Scottie Scheffler",
    Player2: "Jon Rahm",
    Player3: "Bryson DeChambeau",
    Player4: "Shane Lowry",
    Player5: "Tony Finau",
  },
  {
    EntryName: "Lavine #4",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Tommy Fleetwood",
    Player4: "Hideki Matsuyama",
    Player5: "Will Zalatoris",
  },
  {
    EntryName: "Lavine #5",
    Player1: "Scottie Scheffler",
    Player2: "Joaquín Niemann",
    Player3: "Brooks Koepka",
    Player4: "Hideki Matsuyama",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "Liggitt #1",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Jordan Spieth",
    Player4: "Sahith Theegala",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "Liggitt #2",
    Player1: "Scottie Scheffler",
    Player2: "Jordan Spieth",
    Player3: "Russell Henley",
    Player4: "Jon Rahm",
    Player5: "Brooks Koepka",
  },
  {
    EntryName: "B. Senter #1",
    Player1: "Ludvig Åberg",
    Player2: "Collin Morikawa",
    Player3: "Sepp Straka",
    Player4: "Viktor Hovland",
    Player5: "Bryson DeChambeau",
  },
  {
    EntryName: "B. Senteer #2",
    Player1: "Ludvig Åberg",
    Player2: "Rory McIlroy",
    Player3: "Patrick Cantlay",
    Player4: "Bryson DeChambeau",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "Bober #1",
    Player1: "Scottie Scheffler",
    Player2: "Harris English",
    Player3: "Viktor Hovland",
    Player4: "Brooks Koepka",
    Player5: "Akshay Bhatia",
  },
  {
    EntryName: "D. Argo #1",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Jon Rahm",
    Player4: "Will Zalatoris",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "D. Argo #2",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Tony Finau",
    Player4: "Joaquín Niemann",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "D. Argo #3",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Patrick Cantlay",
    Player4: "Will Zalatoris",
    Player5: "Max Homa",
  },
  {
    EntryName: "D. Argo #4",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Bryson DeChambeau",
    Player4: "Viktor Hovland",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "D. Argo #5",
    Player1: "Scottie Scheffler",
    Player2: "Hideki Matsuyama",
    Player3: "Bryson DeChambeau",
    Player4: "Justin Thomas",
    Player5: "Joaquín Niemann",
  },
  {
    EntryName: "D. Argo #6",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Matt Fitzpatrick",
    Player4: "Hideki Matsuyama",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "J. Kassing #1",
    Player1: "Scottie Scheffler",
    Player2: "Sepp Straka",
    Player3: "Will Zalatoris",
    Player4: "Russell Henley",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "J. Kassing #2",
    Player1: "Scottie Scheffler",
    Player2: "Sepp Straka",
    Player3: "Akshay Bhatia",
    Player4: "Jon Rahm",
    Player5: "Justin Rose",
  },
  {
    EntryName: "J. Kassing #3",
    Player1: "Scottie Scheffler",
    Player2: "Sepp Straka",
    Player3: "Jon Rahm",
    Player4: "Will Zalatoris",
    Player5: "Tommy Fleetwood",
  },
  {
    EntryName: "J. Kassing #4",
    Player1: "Scottie Scheffler",
    Player2: "Bryson DeChambeau",
    Player3: "Patrick Reed",
    Player4: "Corey Conners",
    Player5: "Russell Henley",
  },
  {
    EntryName: "Rehland #3",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Bryson DeChambeau",
    Player4: "Cameron Smith",
    Player5: "Phil Mickelson",
  },
  {
    EntryName: "Rehland #4",
    Player1: "Scottie Scheffler",
    Player2: "Jon Rahm",
    Player3: "Tommy Fleetwood",
    Player4: "Shane Lowry",
    Player5: "Justin Rose",
  },
  {
    EntryName: "Summers #1",
    Player1: "Scottie Scheffler",
    Player2: "Viktor Hovland",
    Player3: "Patrick Cantlay",
    Player4: "Bryson DeChambeau",
    Player5: "Justin Thomas",
  },
  {
    EntryName: "Summers #2",
    Player1: "Ludvig Åberg",
    Player2: "Robert MacIntyre",
    Player3: "Bryson DeChambeau",
    Player4: "Max Homa",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "Summers #3",
    Player1: "Scottie Scheffler",
    Player2: "Wyndham Clark",
    Player3: "Tommy Fleetwood",
    Player4: "Min Woo Lee",
    Player5: "Sepp Straka",
  },
  {
    EntryName: "S. Good  #1",
    Player1: "Scottie Scheffler",
    Player2: "Jon Rahm",
    Player3: "Justin Thomas",
    Player4: "Hideki Matsuyama",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "S. Good #2",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Justin Thomas",
    Player4: "Joaquín Niemann",
    Player5: "Min Woo Lee",
  },
  {
    EntryName: "B. Good #1",
    Player1: "Scottie Scheffler",
    Player2: "Justin Thomas",
    Player3: "Jon Rahm",
    Player4: "Jordan Spieth",
    Player5: "Russell Henley",
  },
  {
    EntryName: "B. Good #2",
    Player1: "Scottie Scheffler",
    Player2: "Hideki Matsuyama",
    Player3: "Joaquín Niemann",
    Player4: "Justin Thomas",
    Player5: "Shane Lowry",
  },
  {
    EntryName: "P. Ward #1",
    Player1: "Scottie Scheffler",
    Player2: "Hideki Matsuyama",
    Player3: "Justin Thomas",
    Player4: "Sepp Straka",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "P. Ward #2",
    Player1: "Collin Morikawa",
    Player2: "Lucas Glover",
    Player3: "Bryson DeChambeau",
    Player4: "Corey Conners",
    Player5: "Sergio Garcia",
  },
  {
    EntryName: "P. Ward #3",
    Player1: "Xander Schauffele",
    Player2: "Collin Morikawa",
    Player3: "Cameron Smith",
    Player4: "Brooks Koepka",
    Player5: "Joaquín Niemann",
  },
  {
    EntryName: "P. Ward #4",
    Player1: "Rory McIlroy",
    Player2: "Collin Morikawa",
    Player3: "Russell Henley",
    Player4: "Will Zalatoris",
    Player5: "Tony Finau",
  },
  {
    EntryName: "Myatt #1",
    Player1: "Scottie Scheffler",
    Player2: "Brooks Koepka",
    Player3: "Justin Thomas",
    Player4: "Shane Lowry",
    Player5: "Viktor Hovland",
  },
  {
    EntryName: "P. Smith #1",
    Player1: "Jon Rahm",
    Player2: "Patrick Reed",
    Player3: "Hideki Matsuyama",
    Player4: "Sepp Straka",
    Player5: "Daniel Berger",
  },
  {
    EntryName: "P. Smith #2",
    Player1: "Xander Schauffele",
    Player2: "Keegan Bradley",
    Player3: "Justin Thomas",
    Player4: "Shane Lowry",
    Player5: "Rory McIlroy",
  },
  {
    EntryName: "P. Smith #3",
    Player1: "Scottie Scheffler",
    Player2: "Sepp Straka",
    Player3: "Shane Lowry",
    Player4: "Patrick Reed",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "T. Wheeler #1",
    Player1: "Scottie Scheffler",
    Player2: "Jon Rahm",
    Player3: "Justin Thomas",
    Player4: "Shane Lowry",
    Player5: "Patrick Cantlay",
  },
  {
    EntryName: "Lockwood #1",
    Player1: "Xander Schauffele",
    Player2: "Ludvig Åberg",
    Player3: "Bryson DeChambeau",
    Player4: "Brooks Koepka",
    Player5: "Jon Rahm",
  },
  {
    EntryName: "T. Jacobs #1",
    Player1: "Tommy Fleetwood",
    Player2: "Will Zalatoris",
    Player3: "Tony Finau",
    Player4: "Viktor Hovland",
    Player5: "Brooks Koepka",
  },
  {
    EntryName: "A. James #1",
    Player1: "Collin Morikawa",
    Player2: "Rory McIlroy",
    Player3: "Will Zalatoris",
    Player4: "Justin Thomas",
    Player5: "Joaquín Niemann",
  },
  {
    EntryName: "A. James #2",
    Player1: "Ludvig Åberg",
    Player2: "Collin Morikawa",
    Player3: "Bryson DeChambeau",
    Player4: "Hideki Matsuyama",
    Player5: "Brooks Koepka",
  },
  {
    EntryName: "A. James #3",
    Player1: "Collin Morikawa",
    Player2: "Xander Schauffele",
    Player3: "Bryson DeChambeau",
    Player4: "Justin Thomas",
    Player5: "Joaquín Niemann",
  },
  {
    EntryName: "A. James #4",
    Player1: "Ludvig Åberg",
    Player2: "Rory McIlroy",
    Player3: "Hideki Matsuyama",
    Player4: "Brooks Koepka",
    Player5: "Will Zalatoris",
  },
  {
    EntryName: "D. Pearce #2",
    Player1: "Rory McIlroy",
    Player2: "Xander Schauffele",
    Player3: "Will Zalatoris",
    Player4: "Jordan Spieth",
    Player5: "Brooks Koepka",
  },
  {
    EntryName: "J. Jordan #1",
    Player1: "Rory McIlroy",
    Player2: "Ludvig Åberg",
    Player3: "Brooks Koepka",
    Player4: "Phil Mickelson",
    Player5: "Justin Thomas",
  },
  {
    EntryName: "J. Jordan #2",
    Player1: "Ludvig Åberg",
    Player2: "Rory McIlroy",
    Player3: "Jordan Spieth",
    Player4: "Justin Thomas",
    Player5: "Tom Hoge",
  },
  {
    EntryName: "J. Ramer #1",
    Player1: "Collin Morikawa",
    Player2: "Rory McIlroy",
    Player3: "Hideki Matsuyama",
    Player4: "Viktor Hovland",
    Player5: "Min Woo Lee",
  },
  {
    EntryName: "J. Ramer #2",
    Player1: "Scottie Scheffler",
    Player2: "Corey Conners",
    Player3: "Tommy Fleetwood",
    Player4: "Bryson DeChambeau",
    Player5: "Lucas Glover",
  },
];
export default Entries;

// utilities/CalculateTotal.js
export default function CalculateTotal(scores) {
  const today = new Date().getDay();
  const isThursday = today === 4; // 4 is Thursday

  const validScores = scores
    .map((x) => (x === "E" || x === "e" ? 0 : parseInt(x)))
    .filter((x) => !isNaN(x))
    .sort((a, b) => a - b)
    .slice(0, 3);

  if (validScores.length === 3 || isThursday) {
    return validScores.reduce((a, b) => a + b, 0);
  } else {
    return isThursday ? 0 : "MC";
  }
}
